import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import firebase from "firebase";
import { message } from "antd";

export default function Footer() {
  const { t } = useTranslation();

  const onClickLogout = () => {
    firebase
      .logout()
      .then(() => message.success(t("logged_out")))
      .catch((err) => console.log(err));
  };

  return (
    <footer className="footer">
      <span>
        Powered by <span className="font-weight-semibold">Digital KISS</span>
      </span>
      <LogoutButton onClick={onClickLogout}>{t("logout")}</LogoutButton>
    </footer>
  );
}

const LogoutButton = styled.a`
  font-size: 13px;
  letter-spacing: 1px;
  text-decoration: none;
  color: grey;
`;
