import { message, notification } from "antd";
import { formatFbError, types } from "./errors";
import i18n from "i18next";
import firebase from "firebase";

export const createAuthUser = (reduxFirebase, credentials, profile) => {
  return new Promise((resolve, reject) => {
    reduxFirebase
      .createUser(
        credentials,
        // Profile object (/users/:id)
        profile
      )
      .then(() => {
        sendVerificationMail();
        resolve();
      })
      .catch(function (error) {
        let errorMessage = formatFbError(error.code);
        if (errorMessage.type === types.email) {
          reject(errorMessage);
        }
        if (errorMessage.type === types.password) {
          reject(errorMessage);
        }
        if (errorMessage.type === types.generic)
          notification["warning"]({
            message: errorMessage,
          });
      });
  });
};

const sendVerificationMail = () => {
  firebase
    .auth()
    .currentUser.sendEmailVerification()
    .then(() =>
      message.success({
        content: i18n.t("notifications.invite_link_sent"),
        duration: 8,
      })
    )
    .catch((err) => message.warning(err, 8));
};
