const practiceData = [
  {
    id: "4",
    name: "Doc2Me test 1",
    street: "Houtweg",
    houseNumber: "29",
    addition: null,
    zipCode: "1251 CS",
    city: "Laren",
    country: "NL",
  },
  {
    id: "5",
    name: "Doc2Me test 2",
    street: "Houtweg",
    houseNumber: "29",
    addition: null,
    zipCode: "1251 CS",
    city: "Laren",
    country: "NL",
  },
  {
    id: "6",
    name: "Doc2Me test 3",
    street: "Houtweg",
    houseNumber: "29",
    addition: null,
    zipCode: "1251 CS",
    city: "Laren",
    country: "NL",
  },
  {
    id: "1",
    name: "HQ Healthcare",
    street: "Houtweg",
    houseNumber: "29",
    addition: null,
    zipCode: "1251 CS",
    city: "Laren",
    country: "NL",
  },
  {
    id: "2",
    name: "Franciscus",
    street: "Kleiweg",
    houseNumber: "500",
    addition: null,
    zipCode: "3045 PM",
    city: "Rotterdam",
    country: "NL",
  },
  {
    id: "3",
    name: "Huisartsenmaatschap Duiven",
    street: "Burgemeester van Dorth tot Medlerstraat",
    houseNumber: "3",
    addition: null,
    zipCode: "6921 AS",
    city: "Duiven",
    country: "NL",
  },
];

export default practiceData;
