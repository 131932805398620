const base = process.env.REACT_APP_BASEURL;
const apiBaseUrl = process.env.REACT_APP_API_URL + "/api";
const dashboardUrl = "/dashboard";
const authUrl = "/auth";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  register: authUrl + "/registreren",
  forgotPassword: authUrl + "/wachtwoord-vergeten",
  acceptInvite: authUrl + "/invite/",
  audio: dashboardUrl + "/audio",
  audioList: dashboardUrl + "/audio/audio-list",
  addAudio: dashboardUrl + "/audio/add-audio",
  users: dashboardUrl + "/admin/users",
  support: dashboardUrl + "/support",
  patients: dashboardUrl + "/patients",
  profile: dashboardUrl + "/profile",
  createPatientUser: apiBaseUrl + "/users/patientUser",
};
