import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Form, Input, Layout, Menu, message, Modal } from "antd";
import Logo from "./Logo";
import { onMobileNavToggle, toggleCollapsedNav } from "redux/actions/Theme";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import utils from "utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import urls from "../../urls";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { updateUserProfile } from "../../functions/firebase/users";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const { navCollapsed, navType, headerNavColor, isMobile, currentTheme } = props;
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const { t } = useTranslation();
  const [editProfileForm] = Form.useForm();
  const [editProfileModalVisible, setEditProfileModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === "dark" ? "#00000" : "#ffffff");
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  const onSaveProfile = () => {
    setIsLoading(true);
    editProfileForm.validateFields().then((values) => {
      delete values["email"];
      updateUserProfile(auth.uid, values)
        .then(() => {
          message.success(t("notifications.profile_updated"));
          closeProfileEditModal();
          setIsLoading(false);
        })
        .catch(() => {
          message.error(t("errors.generic"));
          setIsLoading(false);
        });
    });
  };

  const openProfileEditModal = () => {
    setEditProfileModalVisible(true);
    editProfileForm.setFieldsValue({
      firstName: profile.firstName,
      lastName: profile.lastName,
      phone: profile.phone,
      email: profile.email,
    });
  };

  const closeProfileEditModal = () => {
    setEditProfileModalVisible(false);
    editProfileForm.resetFields();
  };

  return (
    <>
      <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
        <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
          <Logo logoType={navMode} />
          <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
            <div className="nav-right d-flex">
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                <Menu mode="horizontal">
                  <Menu.Item key="dashboard">
                    {t("dashboard")}
                    <Link to={urls.dashboard} />
                  </Menu.Item>
                </Menu>
              </ul>
              <a href={"#"} onClick={openProfileEditModal}>
                <ProfileImg src={profile.profileImage} alt={""} />
              </a>
            </div>
          </div>
        </div>
      </Header>
      <Modal
        visible={editProfileModalVisible}
        title={t("edit_profile")}
        onCancel={closeProfileEditModal}
        onOk={onSaveProfile}
        okText={t("save")}
        cancelText={t("close")}
      >
        <Form layout="vertical" form={editProfileForm}>
          <Row>
            <Col lg={6}>
              <Form.Item
                name="firstName"
                label={t("first_name")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_first_name"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item
                name="lastName"
                label={t("last_name")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_last_name"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Item
                name="phone"
                label={t("phone")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_phone_number"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item
                name="email"
                label={t("email")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_email"),
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const ProfileImg = styled.img`
  align-self: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-left: 10px;
  object-fit: cover;
`;

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
