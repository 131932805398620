import { createPatientUser } from "../../urls";
import axios from "axios";
import firebase from "firebase";
import { FIRESTORE_USERS_TABLE } from "../../constants/FirestoreConstant";

export const createPatient = (userObject) => {
  return new Promise((resolve, reject) => {
    axios
      .post(createPatientUser, userObject)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error.response.data.message);
      });
  });
};

/**
 * Update firstName, lastName or phone number of a user
 **/
export const updateUserProfile = (userId, values) => {
  const firestore = firebase.firestore();
  return new Promise((resolve, reject) => {
    firestore
      .collection(FIRESTORE_USERS_TABLE)
      .doc(userId)
      .update(values)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

/**
 * Deactivate user
 **/
export const deactivateUser = (userId) => {
  const firestore = firebase.firestore();
  return new Promise((resolve, reject) => {
    firestore
      .collection(FIRESTORE_USERS_TABLE)
      .doc(userId)
      .update({ active: false })
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};
