import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useSelector } from "react-redux";
import { DOCTOR_ROLE } from "../../constants/UserConstants";

export const AppViews = () => {
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const role = profile.role;
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {role === DOCTOR_ROLE ? (
          <Route path={`${APP_PREFIX_PATH}`} component={lazy(() => import(`./doctor-dashboard`))} />
        ) : (
          <Route path={`${APP_PREFIX_PATH}`} component={lazy(() => import(`./patient-dashboard`))} />
        )}
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
