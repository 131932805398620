import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFirebase } from "react-redux-firebase";
import { dashboard, forgotPassword } from "../../../urls";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

export const LoginForm = () => {
  const firebase = useFirebase();
  const { t } = useTranslation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendVerificationMail = () => {
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() =>
        message.success({
          content: t("notifications.invite_link_sent"),
          duration: 8,
        })
      )
      .catch((err) => message.warning(err, 8));
  };

  const onLogin = (values) => {
    setErrorMessage("");
    setIsLoading(true);
    firebase
      .login({ email: values.email, password: values.password })
      .then((user) => {
        if (!user.user.user.emailVerified) {
          message.warning({
            content: t("notifications.verify_email"),
            duration: 8,
            onClick: sendVerificationMail,
          });
          setIsLoading(false);
        } else {
          history.push(dashboard);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.warning(err.message);
        setErrorMessage(err.code);
      });
  };

  return (
    <Form layout="vertical" name="login-form" onFinish={onLogin} className="mt-4">
      <Form.Item
        name="email"
        label={t("email")}
        rules={[
          {
            required: true,
            message: t("form.enter_email"),
          },
          {
            type: "email",
            message: t("form.enter_valid_email"),
          },
        ]}
      >
        <Input prefix={<MailOutlined className="text-primary" />} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t("form.enter_password"),
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined className="text-primary" />} />
      </Form.Item>
      <div className="w-100 text-center mb-2">
        <span className="text-danger">{errorMessage}</span>
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          {t("login")}
        </Button>
      </Form.Item>
      <ForgotPasswordLink to={forgotPassword}>{t("forgot_password")}</ForgotPasswordLink>
    </Form>
  );
};

const ForgotPasswordLink = styled(Link)`
  text-decoration: none;
`;

export default LoginForm;
