import React from "react";
import { Card } from "antd";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { device } from "../../../../utils/breakpoints";
import { login } from "../../../../urls";
import RegistrationForm from "../../components/RegistrationForm";

const Register = () => {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.theme.currentTheme);
  return (
    <Container fluid className={"h-100"}>
      <Row>
        <Col lg={8} className={"d-flex align-items-center justify-content-center"}>
          <Col xs={10} lg={6}>
            <Card className="border-0">
              <div className="text-center">
                <img
                  className="img-fluid w-50"
                  src={`/img/${theme === "light" ? "logo.png" : "logo-white.png"}`}
                  alt=""
                />
              </div>
              <div className="my-4">
                <Title className={"mt-5"}>{t("sign_up")}</Title>
                <NoAccount>
                  <span>{t("already_got_account?")}</span>
                  <Link to={login}>{t("sign_in")}</Link>
                </NoAccount>
                <Row justify="center">
                  <Col>
                    <RegistrationForm />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Col>
        <Col lg={4} className={"overflow-hidden"}>
          <Image src={"/img/bg/speakers-blue.webp"} />
        </Col>
      </Row>
    </Container>
  );
};

const NoAccount = styled.div`
  display: flex;

  span {
    margin-right: 4px;
  }

  a {
    text-decoration: none;
  }
`;

const Title = styled.h2``;

const Image = styled.img`
  display: none;

  @media ${device.lg} {
    display: block;
    height: 100vh;
    width: 103%;
    object-fit: cover;
  }
`;

export default Register;
