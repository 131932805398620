import React from "react";
import { connect, useSelector } from "react-redux";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import AppViews from "views/app-views";
import { Grid, Layout } from "antd";

import navigationConfig from "configs/NavigationConfig";
import { NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useFirestoreConnect } from "react-redux-firebase";
import { FIRESTORE_RECORDINGS_TABLE, FIRESTORE_USERS_TABLE } from "../../constants/FirestoreConstant";
import { DOCTOR_ROLE, PATIENT_ROLE } from "../../constants/UserConstants";
import firebase from "firebase";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location }) => {
  const uid = useSelector((state) => state.firebaseReducer.auth.uid);
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const role = profile.role;
  const doctors = profile.doctors;
  const queries =
    role === DOCTOR_ROLE
      ? [
          { collection: FIRESTORE_RECORDINGS_TABLE, where: ["doctorId", "==", uid] },
          {
            collection: FIRESTORE_USERS_TABLE,
            where: [
              ["doctors", "array-contains", uid],
              ["role", "==", PATIENT_ROLE],
              ["active", "!=", false],
            ],
            storeAs: "patients",
          },
        ]
      : !!doctors
      ? [
          { collection: FIRESTORE_RECORDINGS_TABLE, where: ["patientId", "==", uid] },
          {
            collection: FIRESTORE_USERS_TABLE,
            where: [[firebase.firestore.FieldPath.documentId(), "in", doctors]],
            storeAs: "doctors",
          },
        ]
      : [{ collection: FIRESTORE_RECORDINGS_TABLE, where: ["patientId", "==", uid] }];
  // : { collection: FIRESTORE_RECORDINGS_TABLE, where: ["patientId", "==", uid] };
  useFirestoreConnect(queries);
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        <Layout className="app-layout" style={{ background: "#f4f4f4" }}>
          <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
