import i18n from "i18next";

export const types = {
  generic: "GENERIC",
  email: "EMAIL",
  password: "PASSWORD",
};

export const formatFbError = (error) => {
  switch (error) {
    case "auth/weak-password":
      return { text: i18n.t("errors.weak_password"), type: types.password };
    case "auth/email-already-in-use":
      return { text: i18n.t("errors.email_in_use"), type: types.email };
    case "auth/email-already-exists":
      return { text: i18n.t("errors.email_in_use"), type: types.email };
    case "auth/invalid-email":
      return { text: i18n.t("errors.invalid_email"), type: types.email };
    case "auth/user-not-found":
      return { text: i18n.t("errors.user_not_found"), type: types.email };
    case "auth/wrong-password":
      return { text: i18n.t("errors.wrong_password"), type: types.password };
    default:
      return { text: i18n.t("errors.generic"), type: types.generic };
  }
};