import _ from "lodash";

export const getUserById = (userId, users) => {
  if (!!userId && !!users) {
    return _.find(users, (item) => item.id === userId);
  } else return null;
};

export const getUserFullName = (user) => {
  if (!!user) return user.firstName + " " + user.lastName;
  else return null;
};

export const getRandomDoctorImage = () => {
  const images = ["https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"];
  return images[Math.floor(Math.random() * images.length)];
};

export const getRandomPatientImage = () => {
  const images = ["https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"];
  return images[Math.floor(Math.random() * images.length)];
};
