import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import urls from "urls";
import login from "./authentication/login";
import register from "./authentication/register";
import forgotPassword from "./authentication/forgot-password";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={urls.login} component={login} />
        <Route path={urls.register} component={register} />
        <Route path={urls.forgotPassword} component={forgotPassword} />
        <Redirect from={urls.auth} to={urls.login} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
