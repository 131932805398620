import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";

// A wrapper that routes user to the dashboard when he/she lands on
// the login page while already authenticated
function CheckAlreadyAuthenticated({ children, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isEmpty(auth) || !auth.emailVerified ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: APP_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
function PrivateRoute({ children, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { location } = props;
  const auth = useSelector((state) => state.firebaseReducer.auth);
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={APP_PREFIX_PATH} />
      </Route>
      <CheckAlreadyAuthenticated path={AUTH_PREFIX_PATH} auth={auth}>
        <AuthLayout />
      </CheckAlreadyAuthenticated>
      <PrivateRoute path={APP_PREFIX_PATH} auth={auth}>
        <AppLayout location={location} />
      </PrivateRoute>
    </Switch>
  );
};

export default withRouter(Views);
