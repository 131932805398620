import React, { useState } from "react";
import { Button, Form, Input, notification, Select } from "antd";
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFirebase } from "react-redux-firebase";
import { DOCTOR_ROLE } from "../../../constants/UserConstants";
import { useHistory } from "react-router-dom";
import practiceData from "../../../data/practice-data";
import { createAuthUser } from "../../../functions/firebase/authentication";
import { dashboard } from "../../../urls";
import { getRandomDoctorImage } from "../../../functions/users";

const { Option } = Select;

export const RegistrationForm = () => {
  const firebase = useFirebase();
  const { t } = useTranslation();
  const history = useHistory();
  const [registrationForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [regErrorMessage, setRegErrorMessage] = useState(null);

  const onRegister = (values) => {
    setIsLoading(true);
    const credentials = { email: values.email, password: values.password };
    const profile = {
      email: values.email,
      role: DOCTOR_ROLE,
      phone: values.phone,
      firstName: values.firstName,
      lastName: values.lastName,
      practiceId: values.practice,
      profileImage: getRandomDoctorImage(),
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
    };
    createAuthUser(firebase, credentials, profile)
      .then(() => {
        notification["success"]({
          message: t("notifications.account_created"),
        });
        history.push(dashboard);
      })
      .catch((err) => {
        setRegErrorMessage(err.text);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Form form={registrationForm} layout="vertical" onFinish={onRegister} className="mt-4">
        <Form.Item
          name="firstName"
          label={t("first_name")}
          rules={[
            {
              required: true,
              message: t("form.enter_first_name"),
            },
          ]}
        >
          <Input prefix={<UserOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={t("last_name")}
          rules={[
            {
              required: true,
              message: t("form.enter_last_name"),
            },
          ]}
        >
          <Input prefix={<UserOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t("phone_number")}
          rules={[
            {
              required: true,
              message: t("form.enter_phone_number"),
            },
          ]}
        >
          <Input prefix={<PhoneOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="practice"
          label={t("practice")}
          rules={[
            {
              required: true,
              message: t("form.choose_practice"),
            },
          ]}
        >
          <Select showSearch optionFilterProp="children" placeholder={t("form.choose_practice")}>
            {!!practiceData &&
              practiceData.map((practice, index) => {
                return (
                  <Option value={practice.id} key={index}>
                    {practice.name + " - " + practice.city}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="email"
          label={t("email")}
          rules={[
            {
              required: true,
              message: t("form.enter_email"),
            },
            {
              type: "email",
              message: t("form.enter_valid_email"),
            },
          ]}
        >
          <Input prefix={<MailOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="password"
          label={t("password")}
          rules={[
            {
              required: true,
              message: t("form.enter_password"),
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </Form.Item>
        <div className="w-100 text-center mb-2">
          <span className="text-danger">{regErrorMessage}</span>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={isLoading}>
            {t("login")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default RegistrationForm;
