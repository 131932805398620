import { AudioOutlined, UserOutlined } from "@ant-design/icons";
import i18n from "translations/i18n";
import urls from "urls";

const shopNavTree = [
  {
    key: "home",
    title: "Doc2Me",
    breadcrumb: false,
    submenu: [
      {
        key: "audio-files",
        path: urls.audio,
        title: "Audio Bestanden",
        icon: AudioOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "patient",
        path: urls.users,
        title: "Patienten",
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const adminNavTree = [
  {
    isAdmin: true,
    key: "admin",
    title: i18n.t("admin"),
    breadcrumb: false,
    submenu: [
      {
        key: "admin-users",
        path: urls.users,
        title: "Artsen",
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...shopNavTree, ...adminNavTree];

export default navigationConfig;
