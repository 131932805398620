import { applyMiddleware, createStore } from "redux";
import reducers from "../reducers";
import firestoreConfig from "configs/FirestoreConfig";
import firebase from "configs/FirebaseConfig";
import { createFirestoreInstance } from "redux-firestore";
import { load, save } from "redux-localstorage-simple";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";

const store = createStore(reducers, load(), composeWithDevTools(applyMiddleware(thunk, save())));

export const rrfProps = {
  firebase,
  config: firestoreConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export default store;
